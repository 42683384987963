// Synchronous styles
import '@styles/layouts/l-search-bar.scss';
import '@styles/components/c-search-holidays.scss';
import '@styles/utilities/u-position-fixed.scss';

const searchBarLoader = () => {
  const searchBarInstanceEl = '#v-search-bar';
  if (!document.querySelector(searchBarInstanceEl)) return;
  import('@commons/searchBar')
    .then(({ default: module }) => module())
    .catch((error) => console.error(error));
}

searchBarLoader();
